body {
    margin: 0;
    min-height: 100vh;
    background-color: #000014;
}

body > * {
    min-height: 100vh;
}

body > * > * {
    min-height: 100vh;
}