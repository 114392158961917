.ResourcePanel {
    display: grid;
    grid-template-areas: "icon name text buttons";
    /* Not sure about this, there probably is a better way */
    grid-template-columns: auto minmax(25%, 1fr) auto auto;
    grid-gap: 8px;

    justify-content: center;
    align-items: center;
}

.ResourcePanel .Icon {
    grid-area: icon;

    font-size: large;
}

.ResourcePanel .Name {
    grid-area: name;

    font-size: large;
    font-weight: 600;
}

.ResourcePanel .Text {
    grid-area: text;
}

.ResourcePanel .Buttons {
    grid-area: buttons;

    display: flex;
    flex-direction: row;
    gap: 4px;
}

.ResourcePanel .Buttons > * {
    width: 96px;
}